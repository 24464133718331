import React from 'react'
import intl from 'react-intl-universal'
import { InviteBanner } from '../../components/banners'
import { Button, Swiper } from '@raysync/common/components'
import { Navigation } from '@raysync/common/services'
import Layout from '../../components/layout'
import {
  Wrapper,
  Banner,
  Section,
  Title,
  ChallengeWrap,
  ChallengeBox,
  ChallengeList,
  ChallengeItem,
  SolutionSection,
  SolutionList,
  SolutionListItem,
  ScenesList,
  ScenesItem,
  ArchSection,
  ScenesSection,
  Container,
} from './file-exchange.atom'
import Icon1 from '@raysync/common/components/svg/solutions/file-exchange/icon1'
import Icon2 from '@raysync/common/components/svg/solutions/file-exchange/icon2'
import Icon3 from '@raysync/common/components/svg/solutions/file-exchange/icon3'
import Icon4 from '@raysync/common/components/svg/solutions/file-exchange/icon4'
import Icon5 from '@raysync/common/components/svg/solutions/file-exchange/icon5'
import Icon6 from '@raysync/common/components/svg/solutions/file-exchange/icon6'
import Icon7 from '@raysync/common/components/svg/solutions/file-exchange/icon7'
import Icon8 from '@raysync/common/components/svg/solutions/file-exchange/icon8'
import Icon9 from '@raysync/common/components/svg/solutions/file-exchange/icon9'
import Icon10 from '@raysync/common/components/svg/solutions/file-exchange/icon10'
import Icon11 from '@raysync/common/components/svg/solutions/file-exchange/icon11'
import Arch from '@raysync/common/components/svg/solutions/file-exchange/arch'
import Image1 from './images/zh/file-exchange/img1.png'
import Image2 from './images/zh/file-exchange/img2.png'
import Image3 from './images/zh/file-exchange/img3.png'
const challengeList = [
  {
    title: 'solution-file-exchange.challenge.item1.title',
    desc: 'solution-file-exchange.challenge.item1.desc',
    icon: <Icon1 />,
    image: Image1,
  },
  {
    title: 'solution-file-exchange.challenge.item2.title',
    desc: 'solution-file-exchange.challenge.item2.desc',
    icon: <Icon2 />,
    image: Image2,
  },
  {
    title: 'solution-file-exchange.challenge.item3.title',
    desc: 'solution-file-exchange.challenge.item3.desc',
    icon: <Icon3 />,
    image: Image3,
  },
]
const solutionList = [
  {
    title: 'solution-file-exchange.solution.item1.title',
    desc: 'solution-file-exchange.solution.item1.desc',
    icon: <Icon4 />,
  },
  {
    title: 'solution-file-exchange.solution.item2.title',
    desc: 'solution-file-exchange.solution.item2.desc',
    icon: <Icon5 />,
  },
  {
    title: 'solution-file-exchange.solution.item3.title',
    desc: 'solution-file-exchange.solution.item3.desc',
    icon: <Icon6 />,
  },
  {
    title: 'solution-file-exchange.solution.item4.title',
    desc: 'solution-file-exchange.solution.item4.desc',
    icon: <Icon7 />,
  },
]
const scenesList = [
  {
    title: 'solution-file-exchange.scenes.item1',
    icon: <Icon8 />,
  },
  {
    title: 'solution-file-exchange.scenes.item2',
    icon: <Icon9 />,
  },
  {
    title: 'solution-file-exchange.scenes.item3',
    icon: <Icon10 />,
  },
  {
    title: 'solution-file-exchange.scenes.item4',
    icon: <Icon11 />,
  },
]

function FileExchange({ location }) {
  return (
    <Layout pageType='solution-file-exchange' location={location}>
      <Container>
        <Banner>
          <Wrapper>
            <h1>{intl.get('solution-file-exchange.banner.title')}</h1>
            <p>{intl.get('solution-file-exchange.banner.desc')}</p>
            <Button
              id='file-exchange'
              onClick={() => {
                Navigation.toApply({ type: 'solution' })
              }}
            >
              {intl.get('solution-file-exchange.banner.button')}
            </Button>
          </Wrapper>
        </Banner>
        <Section top={78} bottom={78}>
          <Wrapper>
            <Title>{intl.get('solution-file-exchange.challenge')}</Title>
            <ChallengeWrap>
              <ChallengeBox>
                <h3>{intl.get('solution-file-exchange.challenge.title')}</h3>
                <p>{intl.getHTML('solution-file-exchange.challenge.desc1')}</p>
                <p>{intl.getHTML('solution-file-exchange.challenge.desc2')}</p>
              </ChallengeBox>
              <ChallengeList>
                {challengeList.map((item, index) => (
                  <ChallengeItem key={index}>
                    {index === 0 ? <span>{intl.get('solution-file-exchange.challenge.traditional')}</span> : null}
                    <img alt={item.title} src={item.image} />
                    <div>
                      <div>
                        {item.icon}
                        <h3>{intl.get(item.title)}</h3>
                      </div>
                      <p>{intl.get(item.desc)}</p>
                    </div>
                  </ChallengeItem>
                ))}
              </ChallengeList>
            </ChallengeWrap>
          </Wrapper>
        </Section>
        <SolutionSection>
          <Wrapper>
            <Title>{intl.get('solution-file-exchange.solution.title')}</Title>
            <p>{intl.get('solution-file-exchange.solution.desc')}</p>
            <SolutionList>
              {solutionList.map((item, index) => (
                <SolutionListItem key={index}>
                  {item.icon}
                  <div>
                    <h3>{intl.get(item.title)}</h3>
                    <p>{intl.get(item.desc)}</p>
                  </div>
                </SolutionListItem>
              ))}
            </SolutionList>
          </Wrapper>
        </SolutionSection>
        <ArchSection>
          <Title>{intl.get('solution-file-exchange.arch.title')}</Title>
          <Arch />
        </ArchSection>
        <ScenesSection>
          <Wrapper>
            <Title>{intl.get('solution-file-exchange.scenes.title')}</Title>
            <ScenesList>
              {scenesList.map(item => (
                <ScenesItem key={item.title}>
                  {item.icon}
                  <p>{intl.get(item.title)}</p>
                </ScenesItem>
              ))}
            </ScenesList>
          </Wrapper>
        </ScenesSection>
      </Container>
      <Swiper>
        <InviteBanner type='solution-file-exchange' variant='inquire' />
      </Swiper>
    </Layout>
  )
}

export default FileExchange
